import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { enUS, es, ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

const localLanguage = navigator.language;

let locale = enUS;

if (localLanguage.startsWith('pt')) {
  locale = ptBR;
} else if (localLanguage.startsWith('es')) {
  locale = es;
}

const DatePickerProvider = ({ children }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
    {children}
  </MuiPickersUtilsProvider>
);

export default DatePickerProvider;
