/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';

import privacyPolicy from '../../assets/privacyPolicy.pdf';
import termsOfUse from '../../assets/termsOfUse.pdf';

function DeactivationDialog() {
  return (
    <Dialog open aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <FormattedMessage
          id="alert.deactivationTitle"
          defaultMessage="Estamos com novidades para você! Baixe agora mesmo o aplicativo!"
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="alert.deactivationContent"
            defaultMessage="A Power Helpers está sempre evoluindo para melhorar a sua experiência, por isso, estamos focando nossas energias em nosso aplicativo. Lembramos que sua conta pode ser acessada normalmente pelo aplicativo e convidamos a testá-lo, clique abaixo na plataforma em que deseja baixar o app para continuar ajudando ou sendo ajudado."
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <a href="https://play.google.com/store/apps/details?id=com.powerhelpersapp">
          <Button
            variant="contained"
            startIcon={<AndroidIcon />}
            color="primary"
          >
            <FormattedMessage
              id="alert.deactivationAndroid"
              defaultMessage="Android"
            />
          </Button>
        </a>
        <a href="https://apps.apple.com/us/app/power-helpers/id1579791882">
          <Button variant="contained" startIcon={<AppleIcon />} color="primary">
            <FormattedMessage id="alert.deactivationIos" defaultMessage="iOS" />
          </Button>
        </a>
      </DialogActions>
    </Dialog>
  );
}

export default DeactivationDialog;
