import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import DatePickerProvider from './utils/datepicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store, persistor } from './store';
import GlobalStyle from './styles/global';
import history from './services/history';
import AppProvider from './hooks';
import Routes from './routes';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <AppProvider>
            <DatePickerProvider>
              <Routes />
              <GlobalStyle />
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </DatePickerProvider>
          </AppProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
