import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 30vw;
  width: 30vw;
  max-width: 200px;
  max-height: 200px;

  background-color: #ff6035;

  margin: 10px;
  padding: 5px;

  border-radius: 15px;
  border: none;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

export const Title = styled.span`
  font-size: calc(0.5rem + 1.2vw);
  letter-spacing: 0.15px;

  text-align: center;

  color: white;
`;
