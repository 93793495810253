import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { Container, Text, ProgressWrapper } from './styles';

function LoadingIndicator() {
  return (
    <Container>
      <ProgressWrapper>
        <CircularProgress />
      </ProgressWrapper>
      <Text>
        <FormattedMessage
          id="placeholder.loadingGeolocation"
          defaultMessage="Looking for needs around you..."
        />
      </Text>
    </Container>
  );
}

export default LoadingIndicator;
