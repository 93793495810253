import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 100vh;
  min-width: 100vw;

  background-color: white;

  text-align: left;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #eee;

  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  flex: 1;
`;

export const Logo = styled.img`
  width: 30vw;
  max-width: 240px;

  margin: 15px 0px 10px 0px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 60px;

  background-color: white;

  padding: 15px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100vw;
  height: 60px;

  position: fixed;
  top: 0;

  background: white;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  z-index: 999;
`;

export const Title = styled.h1`
  font: 400 34px Roboto, sans-serif;
  letter-spacing: 0.25px;
  margin: 0;
`;

export const Subtitle = styled.h2`
  font: 500 20px Roboto, sans-serif;
  letter-spacing: 0.15px;
`;

export const BodyText = styled.p`
  font: 400 16px Roboto, sans-serif;
  letter-spacing: 0.5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 95vw;

  a,
  button {
    min-height: 65px;
  }
`;
