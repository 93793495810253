/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';

import filterImage from '../../assets/filterImage.svg';
import history from '../../services/history';
import api from '../../services/api';
import { store } from '../../store';
import {
  Container,
  Subtitle,
  ButtonWrapper,
  Main,
  Wrapper,
  ImageWrapper,
} from './styles';

const PageContentLoader = () => (
  <Wrapper>
    <ContentLoader
      style={{ height: '40px', width: '200px' }}
      foregroundColor="#ddd"
    >
      <rect rx="8" ry="8" width="200" height="30" />
    </ContentLoader>
    <ContentLoader
      style={{ height: '40px', width: '200px' }}
      foregroundColor="#ddd"
    >
      <rect rx="8" ry="8" width="200" height="30" />
    </ContentLoader>
    <ContentLoader
      style={{ height: '40px', width: '200px' }}
      foregroundColor="#ddd"
    >
      <rect rx="8" ry="8" width="200" height="30" />
    </ContentLoader>
    <ContentLoader
      style={{ height: '40px', width: '200px' }}
      foregroundColor="#ddd"
    >
      <rect rx="8" ry="8" width="200" height="30" />
    </ContentLoader>
  </Wrapper>
);

function MapFilter() {
  const [loading, setLoading] = useState(true);
  const [needs, setNeeds] = useState([]);

  const handleNoFilter = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: 'FILTER',
        filter: 0,
      })
    );

    return history.push('/webviewmap', { filter: 0 });
  };

  useEffect(() => {
    async function fetchNeeds() {
      try {
        const { access_token } = store.getState().auth;

        const response = await api.get('/need/category-list', {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        const { need_categories } = response.data.data;
        setNeeds((needCategoriesList) => [
          ...needCategoriesList,
          ...need_categories,
        ]);
        setLoading(false);
      } catch (err) {
        history.push('/');
        setNeeds([]);
      }
    }

    fetchNeeds();
  }, []);

  return (
    <Container>
      <ImageWrapper>
        <img
          src={filterImage}
          alt="Filter"
          style={{
            width: '50vw',
            height: 'auto',
            margin: '20px 5px 10px',
            maxWidth: '240px',
          }}
        />
      </ImageWrapper>
      <Main>
        <Subtitle>
          <FormattedMessage
            id="subtitle.mapFilter"
            defaultMessage="What kind of need do you want to look for?"
          />
        </Subtitle>
        {loading ? (
          <PageContentLoader />
        ) : (
          <>
            <ButtonWrapper>
              {needs.map((need) => (
                <Button
                  key={need.id}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        action: 'FILTER',
                        filter: need.id,
                      })
                    );

                    return history.push('/webviewmap', {
                      filter: need.id,
                    });
                  }}
                  style={{
                    backgroundColor: '#FF6035',
                    borderRadius: '25px',
                    maxWidth: '400px',
                  }}
                >
                  {need.name}
                </Button>
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNoFilter}
                style={{
                  backgroundColor: '#FF6035',
                  borderRadius: '25px',
                  maxWidth: '400px',
                }}
              >
                <FormattedMessage
                  id="placeholder.showAll"
                  defaultMessage="Show all"
                />
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Main>
    </Container>
  );
}

export default MapFilter;
