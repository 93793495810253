import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { geolocationSuccess, geolocationFailure } from './actions';
import api from '../../../services/api';
import history from '../../../services/history';

const getGotUserLocation = (state) => state.geolocation.gotUserLocation;

const getUserLocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (location) => resolve(location),
      (error) => {
        reject(error);
      },
      { enableHighAccuracy: false, timeout: 15000 }
    );
  });

export function* getGeolocation() {
  const gotUserLocation = yield select(getGotUserLocation);

  if (!gotUserLocation) {
    try {
      const location = yield call(getUserLocation);
      const { latitude, longitude } = location.coords;

      api.defaults.headers.lat = latitude;
      api.defaults.headers.lng = longitude;

      yield put(geolocationSuccess(latitude, longitude));
      history.go();
    } catch (err) {
      yield put(geolocationFailure());
      history.go();
    }
  }
}

export function setLocation({ payload }) {
  if (!payload) return;

  const { lat, lng } = payload;

  if (lat && lng) {
    api.defaults.headers.lat = lat;
    api.defaults.headers.lng = lng;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setLocation),
  takeLatest('@geolocation/GET_LOCATION_REQUEST', getGeolocation),
]);
