import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import NotFound from '../pages/IntroPage';
import IntroPage from '../pages/IntroPage';
import Home from '../pages/Home';
import WebViewMap from '../pages/WebViewMap';
import WebViewMapFilter from '../pages/WebViewMapFilter';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={IntroPage} />
      <Route path="/home" component={Home} isPrivate />
      <Route path="/webviewmap" component={WebViewMap} />
      <Route path="/webviewmapfilter" component={WebViewMapFilter} />
      <Route path="/" component={NotFound} />
    </Switch>
  );
}
