import axios from 'axios';

const baseURL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api.powerhelpers.com/v1'
    : 'https://stagingapi.powerhelpers.com/v1';

const { language } = navigator;

const api = axios.create({
  baseURL,
  headers: {
    'Content-Language': language,
    lat: '',
    lng: '',
  },
});

export default api;
