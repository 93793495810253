import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  z-index: 999;

  min-height: 60px;
  min-width: 70%;

  background-color: white;

  border-radius: 10px;

  padding: 5px 10px;
`;

export const ProgressWrapper = styled.div``;

export const Text = styled.span`
  font: 16px 'Roboto', sans-serif;
  font-weight: bold;

  margin-left: 20px;
`;
