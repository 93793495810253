import React, { createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';

import api from '../services/api';
import { refreshTokenRequest } from '../store/modules/auth/actions';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  api.interceptors.request.use(
    async (config) => {
      if (
        config.url !== 'auth/refresh' &&
        config.url !== 'feedback/count' &&
        config.url !== 'need-flow/list' &&
        config.url.indexOf('need/show') === -1
      ) {
        await dispatch(refreshTokenRequest());
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return <AuthContext.Provider>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
