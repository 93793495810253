/* eslint-disable camelcase */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, Aside, Title } from './styles';
import Menu from '../Menu';
import {
  registerNeedButton,
  GoToFeedbackListButton,
  feedbackGuideButton,
  GuideButton,
  DeleteNeedButton,
  HistoryGoBackButtonArrowIcon,
} from '../HeaderButtons';

export default function Header(props) {
  const { title, page, handler, openFeedbacksNumber } = props;

  const asideComponent = {
    needs: registerNeedButton,
    feedbackList: feedbackGuideButton,
    editNeeds: <DeleteNeedButton handler={handler} />,
    helpList: <GuideButton handler={handler} />,
    messageList: <GuideButton handler={handler} />,
    profile: <GuideButton handler={handler} />,
    home: <GoToFeedbackListButton notificationNumber={openFeedbacksNumber} />,
    helpActions: <GuideButton handler={handler} />,
  };

  const historyPreviousPage = {
    settings: '/home',
    profile: '/home',
    feedbackList: '/home',
    messageList: '/home',
    helpList: '/home',
    needs: '/home',
    map: '/home/map/filter',
    chat: '/messages',
    helpActions: '/help',
    needRegister: '/needs',
  };

  return (
    <Container>
      {page === 'home' ? (
        <Menu />
      ) : (
        <HistoryGoBackButtonArrowIcon route={historyPreviousPage[page]} />
      )}
      <Title>
        {title ? (
          <FormattedMessage id={`title.${title}`} defaultMessage={title} />
        ) : null}
      </Title>
      <Aside>{asideComponent[page]}</Aside>
    </Container>
  );
}
