/* eslint-disable no-param-reassign */
import produce from 'immer';

const INITIAL_STATE = {
  access_token: null,
  expires_in: null,
  signed: false,
  loading: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST':
      case '@auth/SIGN_UP_REQUEST':
      case '@auth/CHANGE_NUMBER_REQUEST':
      case '@auth/SIGN_UP_VERIFICATION_CODE_REQUEST':
      case '@auth/SIGN_IN_VERIFICATION_CODE_REQUEST':
      case '@auth/CHANGE_NUMBER_VERIFICATION_CODE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_FAILURE':
      case '@auth/CHANGE_NUMBER_SUCCESS':
      case '@auth/SIGN_UP_VERIFICATION_CODE_SUCCESS':
      case '@auth/SIGN_IN_VERIFICATION_CODE_SUCCESS':
      case '@auth/CHANGE_NUMBER_VERIFICATION_CODE_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.access_token = action.payload.access_token;
        draft.expires_in = action.payload.expires_in;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_UP_SUCCESS': {
        draft.signed = true;
        draft.loading = false;
        draft.access_token = action.payload.access_token;
        draft.expires_in = action.payload.expires_in;
        break;
      }
      case '@auth/REFRESH_TOKEN_SUCCESS': {
        draft.access_token = action.payload.access_token;
        draft.expires_in = action.payload.expires_in;
        break;
      }
      case '@auth/SIGN_OUT':
      case '@auth/REFRESH_TOKEN_FAILURE': {
        draft.signed = false;
        draft.access_token = null;
        draft.expires_in = null;
        break;
      }
      default:
    }
  });
}
