import React from 'react';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MailRoundedIcon from '@material-ui/icons/MailRounded';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { Badge, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

import history from '../../services/history';

export function HistoryGoBackButtonArrowIcon({ route }) {
  const handlePushToPreviousPage = () => {
    return route ? history.push(route) : history.goBack();
  };

  return (
    <IconButton onClick={handlePushToPreviousPage}>
      <ArrowBackRoundedIcon style={{ fontSize: '30px' }} />
    </IconButton>
  );
}

export function DeleteNeedButton(props) {
  const { handler } = props;

  return (
    <IconButton onClick={handler} style={{ color: 'unset' }}>
      <DeleteRoundedIcon style={{ fontSize: '30px' }} />
    </IconButton>
  );
}

export function GuideButton(props) {
  const { handler } = props;

  return (
    <IconButton onClick={handler} style={{ color: 'unset' }}>
      <HelpOutlineRoundedIcon style={{ fontSize: '30px' }} />
    </IconButton>
  );
}

export const registerNeedButton = (
  <Link className="menu-item" to="/needs/register">
    <AddCircleOutlineRoundedIcon style={{ fontSize: '30px' }} />
  </Link>
);

export const feedbackGuideButton = (
  <Link className="menu-item" to="/feedbacks/guide">
    <HelpOutlineRoundedIcon style={{ fontSize: '30px' }} />
  </Link>
);

export const goBackToHomeButton = (
  <Link className="menu-item" to="/home">
    <CloseRoundedIcon style={{ fontSize: '30px' }} />
  </Link>
);

export function GoToFeedbackListButton(props) {
  const { notificationNumber } = props;

  return (
    <Link style={{ marginRight: '10px' }} to="/feedbacks">
      <Badge
        badgeContent={notificationNumber}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        color="error"
      >
        <RateReviewIcon style={{ fontSize: '30px' }} />
      </Badge>
    </Link>
  );
}

export function GoToMessagesButton(props) {
  const { messageNumber } = props;

  return (
    <Link style={{ marginRight: '5px' }} to="/messages">
      <Badge
        badgeContent={messageNumber}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        color="error"
      >
        <MailRoundedIcon style={{ fontSize: '30px', marginBottom: '4px' }} />
      </Badge>
    </Link>
  );
}
