/* eslint-disable no-param-reassign */
import produce from 'immer';

const INITIAL_STATE = {
  name: null,
  first_name: null,
  last_name: null,
  phoneNumber: null,
  birthDate: null,
  profile: null,
  gpsCoordinates: null,
  id: null,
  saving: false,
  avatar: null,
  email: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.birthDate = action.payload.userProfile.date_of_birth;
        draft.gpsCoordinates = action.payload.gpsCoordinates;
        draft.phoneNumber = action.payload.phoneNumber;
        draft.first_name = action.payload.first_name;
        draft.last_name = action.payload.last_name;
        draft.profile = action.payload.userProfile;
        draft.avatar = action.payload.avatar;
        draft.email = action.payload.email;
        draft.name = action.payload.name;
        draft.id = action.payload.id;
        break;
      }
      case '@auth/SIGN_UP_VERIFICATION_CODE_SUCCESS': {
        draft.phoneNumber = action.payload.phoneNumber;
        draft.birthDate = action.payload.birthDate;
        draft.first_name = action.payload.firstName;
        draft.last_name = action.payload.lastName;
        break;
      }
      case '@auth/CHANGE_NUMBER_SUCCESS': {
        draft.phoneNumber = action.payload.newPhoneNumber;
        break;
      }
      case '@auth/SIGN_IN_VERIFICATION_CODE_SUCCESS': {
        draft.phoneNumber = action.payload.phoneNumber;
        break;
      }
      case '@auth/SIGN_UP_SUCCESS': {
        draft.gpsCoordinates = action.payload.gpsCoordinates;
        draft.phoneNumber = action.payload.phoneNumber;
        draft.first_name = action.payload.first_name;
        draft.last_name = action.payload.last_name;
        draft.profile = action.payload.userProfile;
        draft.avatar = action.payload.avatar;
        draft.email = action.payload.email;
        draft.name = action.payload.name;
        draft.id = action.payload.id;
        break;
      }
      case '@user/PROFILE_UPDATE_REQUEST': {
        draft.saving = true;
        break;
      }
      case '@user/PROFILE_UPDATE_SUCCESS': {
        draft.profile.address = action.payload.address;
        draft.profile.city = action.payload.city;
        draft.profile.state = action.payload.state;
        draft.profile.postal_code = action.payload.postalCode;
        draft.profile.country = action.payload.country;
        draft.profile.number_adults = action.payload.numberAdults;
        draft.profile.number_kids = action.payload.numberKids;
        draft.profile.notes = action.payload.story;
        draft.profile.radio_distance = action.payload.radio_distance;
        draft.profile.max_locations = action.payload.max_locations;
        draft.profile.units = action.payload.units;
        draft.profile.full_name = `${action.payload.first_name} ${action.payload.last_name}`;
        draft.profile.communication_preference =
          action.payload.communicationPreference;
        draft.gpsCoordinates.lat = action.payload.lat;
        draft.gpsCoordinates.lng = action.payload.lng;
        draft.first_name = action.payload.first_name;
        draft.last_name = action.payload.last_name;
        draft.email = action.payload.email;
        draft.saving = false;
        break;
      }
      case '@user/PROFILE_UPDATE_FAILURE': {
        draft.saving = false;
        break;
      }
      case '@auth/REFRESH_TOKEN_FAILURE':
      case '@auth/SIGN_OUT': {
        draft.name = null;
        draft.phoneNumber = null;
        draft.profile = null;
        draft.gpsCoordinates = null;
        draft.birthDate = null;
        draft.id = null;
        draft.avatar = null;
        draft.email = null;
        draft.first_name = null;
        draft.last_name = null;
        break;
      }
      case '@user/AVATAR_UPDATE_SUCCESS': {
        draft.avatar = action.payload.avatar;
        break;
      }
      case '@user/COMMUNICATION_PREFERENCE_UPDATE': {
        draft.profile.communication_preference =
          action.payload.communicationPreference;
        break;
      }
      default:
    }
  });
}
