import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 100vh;
  min-width: 100vw;

  background-color: white;

  text-align: left;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #eee;

  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  flex: 1;
`;

export const Logo = styled.img`
  width: 30vw;
  max-width: 240px;

  margin: 15px 0px 10px 0px;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  .highlightCard {
    animation: pulse-orange 2s infinite;

    span {
      font-weight: bold;
    }
  }

  @keyframes pulse-orange {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 60px;

  background-color: white;

  padding: 15px;
`;

export const Subtitle = styled.span`
  font: 400 16px Roboto, sans-serif;

  letter-spacing: 1.5px;

  text-transform: uppercase;

  margin-bottom: 15px;
`;
