/* eslint-disable camelcase */
import React from 'react';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { format } from 'date-fns';

import history from '../../../services/history';
import api from '../../../services/api';
import {
  signFailure,
  refreshTokenSuccess,
  refreshTokenFailure,
  signUpSuccess,
  signInSuccess,
  signInVerificationCodeSuccess,
  signUpVerificationCodeSuccess,
  changeNumberVerificationCodeSuccess,
  changeNumberSuccess,
} from './actions';

const getUserPhoneNumber = (state) => state.user.phoneNumber;
const getUserBirthDate = (state) => state.user.birthDate;
const getToken = (state) => state.auth.access_token;
const getTokenExpiration = (state) => state.auth.expires_in;
const getFirstName = (state) => state.user.first_name;
const getLastName = (state) => state.user.last_name;

export function* refreshUserToken() {
  const token = yield select(getToken);
  const expiration = yield select(getTokenExpiration);

  const timestampInSeconds = Math.floor(Date.now() / 1000);

  if (token && expiration && expiration - timestampInSeconds <= 5 * 60) {
    try {
      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const body = {};

      const response = yield call(api.post, 'auth/refresh', body, header);

      const { access_token, expires_in } = response.data;

      yield put(refreshTokenSuccess(access_token, expires_in));
    } catch (err) {
      yield put(refreshTokenFailure());
      history.push('/');
    }
  }
}

export function* signInVerificationCodeRequest({ payload }) {
  const { phoneNumber } = payload;

  try {
    yield call(api.post, 'auth/verification-code', {
      to_number: phoneNumber,
    });

    history.push('/otp');

    yield put(signInVerificationCodeSuccess(phoneNumber));
  } catch (err) {
    if (err.response.data.action && err.response.data.action === 'SIGNUP') {
      history.push('/signup', { phoneNumber });
    }

    toast.warning(err.response.data.message);
    yield put(signFailure());
  }
}

export function* changeNumberVerificationCodeRequest({ payload }) {
  try {
    const { phoneNumber } = payload;

    const token = yield select(getToken);

    yield call(
      api.post,
      'users/change-phone-number/verification-code',
      {
        phone_number: phoneNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    history.push('/profile/otp', { newPhoneNumber: phoneNumber });

    yield put(changeNumberVerificationCodeSuccess(phoneNumber));
  } catch (err) {
    toast.warning(err.response.data.message);
    yield put(signFailure());
  }
}

export function* changeNumberRequest({ payload }) {
  try {
    const { newPhoneNumber, verificationCode } = payload;

    const token = yield select(getToken);

    yield call(
      api.post,
      'users/change-phone-number',
      {
        phone_number: newPhoneNumber,
        verification_code: verificationCode,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    yield put(changeNumberSuccess(verificationCode, newPhoneNumber));

    history.push('/profile');

    toast.success(
      <FormattedMessage
        id="toast.changeNumberSuccess"
        defaultMessage="Your phone number has been successfully changed and your account details have already been migrated!"
      />,
      {
        autoClose: 10000,
      }
    );
  } catch (err) {
    toast.warning(err.response.data.message);
    yield put(signFailure());
  }
}

export function* signIn({ payload }) {
  try {
    const { verificationCode } = payload;

    const phoneNumber = yield select(getUserPhoneNumber);

    const response = yield call(api.post, 'auth/login', {
      phone_number: phoneNumber,
      verification_code: verificationCode,
      otp: 1,
    });

    const { access_token, expires_in } = response.data;

    const profile = yield call(api.get, 'auth/authenticated-user', {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    const {
      name,
      first_name,
      last_name,
      user_profile,
      gps_coordinates,
      id,
      avatar,
      email,
    } = profile.data.data;

    yield put(
      signInSuccess(
        access_token,
        expires_in,
        name,
        phoneNumber,
        user_profile,
        gps_coordinates,
        id,
        avatar,
        email,
        first_name,
        last_name
      )
    );

    history.push('/home');
  } catch (err) {
    toast.error(err.response.data.message);
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { verificationCode } = payload;

    const phoneNumber = yield select(getUserPhoneNumber);
    const birthDate = yield select(getUserBirthDate);
    const firstName = yield select(getFirstName);
    const lastName = yield select(getLastName);

    const response = yield call(api.post, 'users/signup', {
      phone_number: phoneNumber,
      date_of_birth: birthDate,
      verification_code: verificationCode,
      otp: 1,
      first_name: firstName,
      last_name: lastName,
    });

    const { access_token, expires_in } = response.data;

    const profile = yield call(api.get, 'auth/authenticated-user', {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    const {
      name,
      first_name,
      last_name,
      user_profile,
      gps_coordinates,
      id,
      avatar,
      email,
    } = profile.data.data;

    yield put(
      signUpSuccess(
        name,
        phoneNumber,
        user_profile,
        gps_coordinates,
        access_token,
        expires_in,
        id,
        avatar,
        email,
        first_name,
        last_name
      )
    );

    history.push('/profile?newUser=true');
  } catch (err) {
    toast.error(err.response.data.message);
    yield put(signFailure());
  }
}

export function* signUpVerificationCodeRequest({ payload }) {
  const { firstName, lastName, phoneNumber, birthDate } = payload;

  const formattedBirthDate = format(birthDate, 'yyyy/MM/dd');

  try {
    yield call(api.post, 'users/verification-code', {
      to_number: phoneNumber,
      date_of_birth: formattedBirthDate,
      first_name: firstName,
      last_name: lastName,
    });

    yield put(
      signUpVerificationCodeSuccess(
        firstName,
        lastName,
        phoneNumber,
        formattedBirthDate
      )
    );

    history.push(`/otp?signup=true`);
  } catch (err) {
    if (err.response.data.action && err.response.data.action === 'LOGIN') {
      history.push('/login', { phoneNumber });
    }

    toast.warning(err.response.data.message, {
      autoClose: 15000,
    });
    yield put(signFailure());
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/REFRESH_TOKEN_REQUEST', refreshUserToken),
  takeLatest('@auth/CHANGE_NUMBER_REQUEST', changeNumberRequest),
  takeLatest(
    '@auth/CHANGE_NUMBER_VERIFICATION_CODE_REQUEST',
    changeNumberVerificationCodeRequest
  ),
  takeLatest(
    '@auth/SIGN_IN_VERIFICATION_CODE_REQUEST',
    signInVerificationCodeRequest
  ),
  takeLatest(
    '@auth/SIGN_UP_VERIFICATION_CODE_REQUEST',
    signUpVerificationCodeRequest
  ),
]);
