/* eslint-disable react/prop-types */
import React from 'react';
import RecordVoiceOverRoundedIcon from '@material-ui/icons/RecordVoiceOverRounded';
import { Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import { FormattedMessage } from 'react-intl';

import Card from '../Card';

function GuidanceDialog({ value, setValue }) {
  return (
    <Dialog
      open={value}
      onClose={() => setValue(!value)}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { padding: '40px 30px' } }}
    >
      <DialogTitle style={{ textAlign: 'center' }} id="form-dialog-title">
        <FormattedMessage
          id="alert.guidanceDialogContent"
          defaultMessage="Next step!"
        />
      </DialogTitle>
      <DialogActions style={{ marginTop: '20px', flexDirection: 'column' }}>
        <Card
          titleStyle={{ fontSize: 'calc(1rem + 1vw)', fontWeigth: 'bold' }}
          style={{ width: '50vw', height: '40vw' }}
          title={
            <FormattedMessage id="menu.needs" defaultMessage="Ask for help" />
          }
          icon={
            <RecordVoiceOverRoundedIcon
              style={{ width: '70%', height: '70%', fill: 'white' }}
            />
          }
          route="/needs/register"
        />
        <Card
          titleStyle={{ fontSize: 'calc(1rem + 1vw)', fontWeigth: 'bold' }}
          style={{ width: '50vw', height: '40vw' }}
          title={
            <FormattedMessage
              id="menu.helpPeople"
              defaultMessage="I can help"
            />
          }
          icon={
            <MapRoundedIcon
              style={{ width: '70%', height: '70%', fill: 'white' }}
            />
          }
          route="/home/map/filter"
        />
      </DialogActions>
    </Dialog>
  );
}

export default GuidanceDialog;
