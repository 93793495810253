/* eslint-disable camelcase */
import React from 'react';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { profileUpdateSuccess, profileUpdateFailure } from './actions';

import history from '../../../services/history';
import api from '../../../services/api';

const getToken = (state) => state.auth.access_token;
const getCategory = (state) => state.user.profile.category;

const injectGetMessage = (fn) =>
  React.createElement(injectIntl(({ intl }) => fn(intl.formatMessage)));

export function* profileUpdate({ payload }) {
  const {
    address,
    city,
    state,
    postalCode,
    country,
    numberAdults,
    numberKids,
    story,
    radio_distance,
    max_locations,
    units,
    first_name,
    last_name,
    email,
    communicationPreference,
  } = payload;

  const token = yield select(getToken);
  const category = yield select(getCategory);

  try {
    const profileResponse = yield call(
      api.patch,
      'users/profile-update',
      {
        address,
        city,
        state,
        postal_code: postalCode,
        country,
        number_adults: numberAdults,
        number_kids: numberKids,
        notes: story,
        category,
        full_name: `${first_name} ${last_name}`,
        radio_distance,
        max_locations,
        units,
        first_name,
        last_name,
        email,
        communication_preference: communicationPreference,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { lat, lng } = profileResponse.data.data;

    yield put(
      profileUpdateSuccess(
        address,
        city,
        state,
        postalCode,
        country,
        numberAdults,
        numberKids,
        story,
        radio_distance,
        max_locations,
        units,
        lat,
        lng,
        first_name,
        last_name,
        email,
        communicationPreference
      )
    );

    history.push('/home?guidanceDialog=true');

    toast.success(
      injectGetMessage((getMessage) =>
        getMessage({
          id: 'toast.profileUpdateSuccess',

          defaultMessage: 'Your profile has been successfully updated!',
        })
      )
    );
  } catch (err) {
    toast.error(
      injectGetMessage((getMessage) =>
        getMessage({
          id: 'toast.profileUpdateError',
          defaultMessage:
            'There was a problem updating your profile, try again',
        })
      )
    );
    yield put(profileUpdateFailure());
  }
}

export default all([takeLatest('@user/PROFILE_UPDATE_REQUEST', profileUpdate)]);
