import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/browser';
import App from './App';
import English from './languages/en.json';
import Portuguese from './languages/pt.json';
import Spanish from './languages/es.json';

if (
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'staging'
) {
  Sentry.init({
    dsn:
      'https://d498ea4453a940319223a3eacb662710@o393047.ingest.sentry.io/5249785',
    environment: process.env.REACT_APP_ENV,
  });
}

const localLanguage = navigator.language;
let applicationLanguage;
if (localLanguage.startsWith('en')) {
  applicationLanguage = English;
} else if (localLanguage.startsWith('pt')) {
  applicationLanguage = Portuguese;
} else if (localLanguage.startsWith('es')) {
  applicationLanguage = Spanish;
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={localLanguage} messages={applicationLanguage}>
      <App />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
