import React from 'react';

import { Container, Title } from './styles';
import history from '../../../services/history';

export default function Card({
  className,
  title,
  icon,
  route,
  style,
  titleStyle,
}) {
  return (
    <Container
      onClick={() => history.push(route)}
      className={className}
      style={style}
    >
      {icon}
      <Title style={titleStyle}>{title}</Title>
    </Container>
  );
}
