import React from 'react';
import L from 'leaflet';
import { Button } from '@material-ui/core';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { FormattedMessage } from 'react-intl';

const customUserMarker = L.icon({
  iconUrl: require('../../assets/my_location-18dp.svg'),
  iconSize: [50, 120],
  popupAnchor: [0, -20],
});

const PopupMarker = ({
  number_family_total,
  number_adults,
  number_kids,
  notes,
  gps_coordinates,
  help_category,
  userIsLoggedIn,
  need_id,
  webview,
  filter,
}) => (
  <Marker position={gps_coordinates}>
    <Popup>
      <span>
        <FormattedMessage
          id="map.popupAdultsNumber"
          values={{
            number_adults,
            b: (word) => <b>{word}</b>,
            code: () => <br />,
            linebreak: <br />,
          }}
          defaultMessage="<b>Number of adults:</b> {number_adults}{linebreak}"
        />
        <FormattedMessage
          id="map.popupKidsNumber"
          values={{
            number_kids,
            b: (word) => <b>{word}</b>,
            code: () => <br />,
            linebreak: <br />,
          }}
          defaultMessage="<b>Number of kids:</b> {number_kids}{linebreak}"
        />
        <FormattedMessage
          id="map.popupFamilySize"
          values={{
            number_family_total,
            b: (word) => <b>{word}</b>,
            code: () => <br />,
            linebreak: <br />,
          }}
          defaultMessage="<b>Family size:</b> {number_family_total}{linebreak}"
        />
        <FormattedMessage
          id="map.popupNeeds"
          values={{
            number_family_total,
            b: (word) => <b>{word}</b>,
            linebreak: <br />,
          }}
          defaultMessage="<b>Needs:</b> "
        />
        <span>
          {help_category}
          <br />
        </span>
        <FormattedMessage
          id="map.popupHistory"
          values={{ notes, b: (word) => <b>{word}</b> }}
          defaultMessage="<b>My history:</b> {notes}"
        />
      </span>
      {userIsLoggedIn ? (
        <>
          <br />
          <br />
          {webview ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                color: 'white',
                width: '100%',
                backgroundColor: '#FF6035',
                borderRadius: '25px',
              }}
              onClick={() =>
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    action: 'NAVIGATE',
                    screen: 'needDetails',
                    need_id,
                    filter,
                  })
                )
              }
            >
              <FormattedMessage
                id="placeholder.seeMoreDetails"
                defaultMessage="See more details"
              />
            </Button>
          ) : (
            <Button
              href={`/needs/details/${need_id}`}
              variant="contained"
              color="primary"
              size="small"
              style={{
                color: 'white',
                width: '100%',
                backgroundColor: '#FF6035',
                borderRadius: '25px',
              }}
            >
              <FormattedMessage
                id="placeholder.seeMoreDetails"
                defaultMessage="See more details"
              />
            </Button>
          )}
        </>
      ) : null}
    </Popup>
  </Marker>
);

const MarkersList = ({ markers, userIsLoggedIn, webview, filter }) => {
  if (markers) {
    const items = markers.map(({ ...props }) => (
      <PopupMarker
        key={props.need_id}
        {...props}
        userIsLoggedIn={userIsLoggedIn}
        webview={webview}
        filter={filter}
      />
    ));
    return <>{items}</>;
  }
  return null;
};

export default function PowerHelpersMap(props) {
  return (
    <Map
      center={[props.location.lat, props.location.lng]}
      zoom={10}
      maxZoom={16}
      minZoom={3}
      zoomControl={false}
      dragging={props.webview ? props.webview : false}
      tap={false}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
      />
      <MarkerClusterGroup>
        <MarkersList
          markers={props.nearbyPeople}
          userIsLoggedIn={props.userIsLoggedIn}
          webview={props.webview ? props.webview : false}
          filter={props.filter}
        />
        {props.gotUserLocation ? (
          <Marker
            icon={customUserMarker}
            position={[props.location.lat, props.location.lng]}
          >
            <Popup>
              <FormattedMessage
                id="map.userPopup"
                defaultMessage="You are here!"
              />
            </Popup>
          </Marker>
        ) : (
          ''
        )}
      </MarkerClusterGroup>
    </Map>
  );
}
