/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from '@material-ui/core';

import LoadingIndicator from './components/LoadingIndicator';
import Map from '../../components/Map';
import api from '../../services/api';

import './styles.css';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

const handleDialogRefuse = () => {
  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      action: 'NAVIGATE',
      screen: 'Home',
    })
  );
};

class WebViewMap extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(props.location.search);
    const urlFilter = urlParams.get('filter');

    this.state = {
      lat: -23.55052,
      lng: -46.633308,
      dialogIsOpen: false,
      nearbyPeople: [],
      loading: true,
      filter: urlFilter || props?.location?.state?.filter,
      addressLocation: JSON.parse(localStorage.getItem('webviewdata'))?.user
        ?.gps_coordinates,
      userGeolocation: JSON.parse(localStorage.getItem('webviewdata'))
        ?.geolocation,
    };
  }

  async componentDidMount() {
    const appLatitude = this.state.userGeolocation?.lat;
    const appLongitude = this.state.userGeolocation?.lng;

    const addressLat = this.state.addressLocation?.lat;
    const addressLng = this.state.addressLocation?.lng;

    if (appLatitude !== null && appLongitude !== null) {
      this.setState({
        lat: appLatitude,
        lng: appLongitude,
        dialogIsOpen: true,
      });

      this.getNearbyNeeds(appLatitude, appLongitude);
    } else if (addressLat !== '' && addressLng !== '') {
      this.setState({
        lat: addressLat,
        lng: addressLng,
        dialogIsOpen: false,
      });

      this.getNearbyNeeds(addressLat, addressLng);
    }
  }

  async getNearbyNeeds(lat, lng) {
    const { access_token } = JSON.parse(
      localStorage.getItem('webviewdata')
    )?.auth;

    try {
      if (lat && lng) {
        const response = await api.post(
          '/need/list',
          {
            lat,
            lng,
            category: this.state.filter,
          },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        const nearbyPeopleResponse = response.data.data.needs;

        if (
          !Array.isArray(nearbyPeopleResponse) ||
          !nearbyPeopleResponse.length
        ) {
          toast.warning(
            <FormattedMessage
              id="toast.noNeedAround"
              defaultMessage="Thanks for your help, currently there is no register of families that need help near you. You can increase the search radius in the settings or come back later."
            />,
            {
              autoClose: 15000,
            }
          );
        }

        this.setState({ nearbyPeople: nearbyPeopleResponse, loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleDialogConfirmation = () => {
    this.setState({
      dialogIsOpen: true,
    });
  };

  render() {
    return (
      <>
        <Wrapper>
          <Map
            nearbyPeople={this.state.nearbyPeople}
            location={{ lat: this.state.lat, lng: this.state.lng }}
            gotUserLocation={this.state.dialogIsOpen}
            userIsLoggedIn
            webview
            filter={this.state.filter}
          />
        </Wrapper>

        {this.state.loading && this.state.dialogIsOpen ? (
          <LoadingIndicator />
        ) : null}

        <Dialog open={!this.state.dialogIsOpen}>
          <DialogTitle>
            <FormattedMessage
              id="alert.unableToGetLocationTitle"
              defaultMessage="Unable to get location"
            />
          </DialogTitle>
          <DialogContent>
            {this.state.userGeolocation?.lat === null &&
            this.state.userGeolocation?.lng === null &&
            this.state.addressLocation?.lat &&
            this.state.addressLocation?.lng ? (
              <DialogContentText>
                <FormattedMessage
                  id="alert.unableToGetLocationContentProceedWithAddress"
                  defaultMessage="Please allow Power Helpers to access your location for a better experience. In the meantime, we will use your address location to proceed."
                />
              </DialogContentText>
            ) : (
              <DialogContentText>
                <FormattedMessage
                  id="alert.unableToGetLocationContent"
                  defaultMessage="To find people who need help near you, please make sure to let Power Helpers access your location. After you do this, we can find people around you who need help."
                />
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            {this.state.userGeolocation?.lat === null &&
            this.state.userGeolocation?.lng === null &&
            this.state.addressLocation?.lat &&
            this.state.addressLocation?.lng ? null : (
              <Button onClick={handleDialogRefuse} color="secondary">
                <FormattedMessage
                  id="alert.unableToGetLocationAction"
                  defaultMessage="Go back"
                />
              </Button>
            )}
            {this.state.userGeolocation?.lat === null &&
            this.state.userGeolocation?.lng === null &&
            this.state.addressLocation?.lat &&
            this.state.addressLocation?.lng ? (
              <Button onClick={this.handleDialogConfirmation} color="primary">
                <FormattedMessage id="common.gotIt" defaultMessage="Got it!" />
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default WebViewMap;
