import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';

import logoImg from '../../assets/logo.png';
import DeactivationDialog from '../../components/DeactivationDialog';
import {
  BodyText,
  ButtonWrapper,
  Container,
  Header,
  Logo,
  Main,
  Title,
  Subtitle,
  ImageWrapper,
} from './styles';

function IntroPage() {
  const [needHelpDialog, setNeedHelpDialog] = useState(false);

  const handleNeedHelpDialogOpen = () => {
    setNeedHelpDialog(true);
  };

  const handleNeedHelpDialogClose = () => {
    setNeedHelpDialog(false);
  };

  return (
    <Container>
      <Header>
        <Title>Power Helpers</Title>
      </Header>
      <ImageWrapper>
        <Logo src={logoImg} alt="Power Helpers" />
      </ImageWrapper>
      <Main>
        <Accordion
          defaultExpanded
          style={{ margin: '20px', maxWidth: '400px' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Subtitle>
              <FormattedMessage
                id="subtitle.helpAndBeHelped"
                defaultMessage="Let's do good to others"
              />
            </Subtitle>
          </AccordionSummary>
          <AccordionDetails>
            <BodyText>
              <FormattedMessage
                id="placeholder.welcomeToPowerHelpers"
                defaultMessage="Welcome to Power Helpers! We are a platform to bring people who need help closer to people who can help, be part of it too."
              />
            </BodyText>
          </AccordionDetails>
        </Accordion>
        <ButtonWrapper>
          <Button
            onClick={handleNeedHelpDialogOpen}
            variant="contained"
            color="primary"
            style={{
              backgroundColor: '#FF6035',
              borderRadius: '25px',
              width: '50%',
              marginRight: '5px',
              maxWidth: '200px',
            }}
          >
            <FormattedMessage id="menu.needs" defaultMessage="Ask for help" />
          </Button>
          <Link
            to="/map/filter"
            style={{ width: '50%', marginLeft: '5px', maxWidth: '200px' }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: '#FF6035',
                borderRadius: '25px',
                width: '100%',
              }}
            >
              <FormattedMessage
                id="menu.helpPeople"
                defaultMessage="I can help"
              />
            </Button>
          </Link>
        </ButtonWrapper>
      </Main>

      <Dialog open={needHelpDialog} onClose={handleNeedHelpDialogClose}>
        <DialogTitle>
          <FormattedMessage
            id="dialog.needHelpTitle"
            defaultMessage="We are very happy for you!"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id="dialog.needHelpContent"
              defaultMessage="Thank you very much for your interest and trust, we hope your need will be met as soon as possible. To do this, sign up for the application and tell us what you need, you will be notified as soon as someone starts your help."
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/login">
            <Button
              color="primary"
              variant="contained"
              style={{ backgroundColor: '#FF6035', borderRadius: '25px' }}
            >
              <FormattedMessage id="action.login" defaultMessage="Login" />
            </Button>
          </Link>
          <Link to="/signup">
            <Button
              color="primary"
              variant="contained"
              style={{ backgroundColor: '#FF6035', borderRadius: '25px' }}
            >
              <FormattedMessage id="action.signup" defaultMessage="Signup" />
            </Button>
          </Link>
        </DialogActions>
      </Dialog>

      <DeactivationDialog />
    </Container>
  );
}

export default IntroPage;
