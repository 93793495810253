import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 0;
  justify-content: space-between;
`;

export const Aside = styled.aside`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  right: 20px;

  a,
  g {
    color: #212529;
  }
`;

export const Title = styled.span`
  position: absolute;
  display: inline;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform: translate3d(-50%, -50%, 0);
  font-weight: 400;
  margin: 0;
  font-size: 1.7em;
  text-align: center;
`;
