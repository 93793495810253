import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .blink {
    animation: blinker 1s alternate infinite;
  }

  .blinkWhiteToOrange {
    animation: whiteToOrangeBlinker 1s alternate infinite;
  }

  @keyframes blinker {
    from {
      background-color: #ff6035;
    }

    to {
      background-color: #4caf50;
    }
  }

  @keyframes whiteToOrangeBlinker {
    from {
      background-color: white;
    }

    to {
      background-color: #ff6035;
    }
  }
`;
