export function geolocationRequest() {
  return {
    type: '@geolocation/GET_LOCATION_REQUEST',
  };
}

export function geolocationSuccess(lat, lng) {
  return {
    type: '@geolocation/GET_LOCATION_SUCCESS',
    payload: { lat, lng },
  };
}

export function geolocationFailure() {
  return {
    type: '@geolocation/GET_LOCATION_FAILURE',
  };
}
