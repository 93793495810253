import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 100vh;
  min-width: 100vw;

  background-color: white;

  text-align: left;
`;

export const Subtitle = styled.h2`
  font: 500 20px Roboto, sans-serif;
  letter-spacing: 0.15px;
  text-align: center;

  margin: 0.5rem 2rem 0.5rem;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 60px;

  background-color: white;
`;

export const BodyText = styled.p`
  font: 400 16px Roboto, sans-serif;
  letter-spacing: 0.5px;
  /* color: white; */

  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90%;

  button {
    margin: 0.5rem 10px;

    width: 100%;
  }

  margin-bottom: 10px;
`;

export const LoginSignupButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 90%;

  margin-top: 15px;

  a,
  button {
    min-height: 65px;
  }

  margin-bottom: 10px;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #eee;

  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  flex: 1;
`;

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100vw;
  height: 60px;

  position: fixed;
  top: 0;

  background: white;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`;
