export function profileUpdateRequest(
  address,
  city,
  state,
  postalCode,
  country,
  numberAdults,
  numberKids,
  story,
  radio_distance,
  max_locations,
  units,
  first_name,
  last_name,
  email,
  communicationPreference
) {
  return {
    type: '@user/PROFILE_UPDATE_REQUEST',
    payload: {
      address,
      city,
      state,
      postalCode,
      country,
      numberAdults,
      numberKids,
      story,
      radio_distance,
      max_locations,
      units,
      first_name,
      last_name,
      email,
      communicationPreference,
    },
  };
}

export function profileUpdateSuccess(
  address,
  city,
  state,
  postalCode,
  country,
  numberAdults,
  numberKids,
  story,
  radio_distance,
  max_locations,
  units,
  lat,
  lng,
  first_name,
  last_name,
  email,
  communicationPreference
) {
  return {
    type: '@user/PROFILE_UPDATE_SUCCESS',
    payload: {
      address,
      city,
      state,
      postalCode,
      country,
      numberAdults,
      numberKids,
      story,
      radio_distance,
      max_locations,
      units,
      lat,
      lng,
      first_name,
      last_name,
      email,
      communicationPreference,
    },
  };
}

export function profileUpdateFailure() {
  return {
    type: '@user/PROFILE_UPDATE_FAILURE',
  };
}

export function avatarUpdateSuccess(avatar) {
  return {
    type: '@user/AVATAR_UPDATE_SUCCESS',
    payload: {
      avatar,
    },
  };
}

export function communicationPreferenceUpdate(communicationPreference) {
  return {
    type: '@user/COMMUNICATION_PREFERENCE_UPDATE',
    payload: {
      communicationPreference,
    },
  };
}
