import React, { useEffect, useState } from 'react';
import RecordVoiceOverRoundedIcon from '@material-ui/icons/RecordVoiceOverRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import { FormattedMessage } from 'react-intl';
import { Badge } from '@material-ui/core';

import DeactivationDialog from '../../components/DeactivationDialog';
import Header from '../../components/Header';
import logoImg from '../../assets/logo.png';
import GuidanceDialog from './GuidanceDialog';
import api from '../../services/api';
import { store } from '../../store';
import Card from './Card';
import {
  Container,
  Main,
  Logo,
  CardsWrapper,
  ImageWrapper,
  Subtitle,
} from './styles';

function Home(props) {
  const urlParams = new URLSearchParams(props.location.search);
  const guidanceDialog = urlParams.get('guidanceDialog');

  const [showGuidanceDialog, setShowGuidanceDialog] = useState(
    guidanceDialog === 'true'
  );
  const [openFeedbacksNumber, setOpenFeedbacksNumber] = useState(0);
  const [messageCount, setMessageCount] = useState(0);

  useEffect(() => {
    const { access_token } = store.getState().auth;

    async function fetchMessageCount() {
      try {
        const countResponse = await api.get(`need-flow-messages/count`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        const count = countResponse.data.data;

        setMessageCount(count);
      } catch (err) {
        setMessageCount(0);
      }
    }

    async function fetchOpenFeedbackCount() {
      try {
        const feedbackCountResponse = await api.get(`feedback/count`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        const feedbackCount = feedbackCountResponse.data.data;

        setOpenFeedbacksNumber(feedbackCount);
      } catch (err) {
        setOpenFeedbacksNumber(0);
      }
    }

    fetchOpenFeedbackCount();
    fetchMessageCount();
  }, []);

  return (
    <>
      <Container>
        <Header
          page="home"
          title="Home"
          openFeedbacksNumber={openFeedbacksNumber}
        />
        <ImageWrapper>
          <Logo src={logoImg} alt="Power Helpers" />
          <Subtitle>Power Helpers</Subtitle>
        </ImageWrapper>
        <Main>
          <CardsWrapper>
            <Card
              className="highlightCard"
              title={
                <FormattedMessage
                  id="menu.needs"
                  defaultMessage="Ask for help"
                />
              }
              icon={
                <RecordVoiceOverRoundedIcon
                  style={{ width: '70%', height: '70%', fill: 'white' }}
                />
              }
              route="/needs"
            />
            <Card
              className="highlightCard"
              title={
                <FormattedMessage
                  id="menu.helpPeople"
                  defaultMessage="I can help"
                />
              }
              icon={
                <MapRoundedIcon
                  style={{ width: '70%', height: '70%', fill: 'white' }}
                />
              }
              route="/home/map/filter"
            />
            <Card
              title={
                <FormattedMessage
                  id="menu.messages"
                  defaultMessage="Messages"
                />
              }
              icon={
                <Badge
                  badgeContent={messageCount}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  color="secondary"
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '70%',
                    height: '70%',
                  }}
                >
                  <EmailRoundedIcon
                    style={{ width: '100%', height: '100%', fill: 'white' }}
                  />
                </Badge>
              }
              route="/messages"
            />
            <Card
              title={
                <FormattedMessage
                  id="menu.help"
                  defaultMessage="Who am I helping"
                />
              }
              icon={
                <PeopleAltRoundedIcon
                  style={{ width: '70%', height: '70%', fill: 'white' }}
                />
              }
              route="/help"
            />
          </CardsWrapper>
        </Main>
      </Container>

      <GuidanceDialog
        value={showGuidanceDialog}
        setValue={setShowGuidanceDialog}
      />

      <DeactivationDialog />
    </>
  );
}

export default Home;
