import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';

import { persistReducer } from 'redux-persist';
import auth from './auth/reducer';
import user from './user/reducer';
import geolocation from './geolocation/reducer';

const geolocationPersistConfig = {
  key: 'geolocation',
  storage: storageSession,
};

export default combineReducers({
  auth,
  user,
  geolocation: persistReducer(geolocationPersistConfig, geolocation),
});
