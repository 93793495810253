/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import RecordVoiceOverRoundedIcon from '@material-ui/icons/RecordVoiceOverRounded';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import MailRoundedIcon from '@material-ui/icons/MailRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Drawer,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
} from '@material-ui/core';

import { UsernameHeadline, PhoneNumberSubtitle, MenuHeader } from './styles';
import { signOut } from '../../store/modules/auth/actions';
import { store } from '../../store';
import './styles.css';

export default function ApplicationMenu(props) {
  const dispatch = useDispatch();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <MenuRoundedIcon style={{ fontSize: '40px', fill: '#212529' }} />
      </IconButton>
      <Drawer anchor="left" open={menuOpen} onClose={handleMenuClose}>
        <Link to="/profile">
          <MenuHeader className="menu-item">
            <Avatar
              src={store.getState().user.avatar}
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '100px',
                marginRight: '5px',
              }}
            />
            <div>
              <UsernameHeadline>
                {store.getState().user.gpsCoordinates.lat !== '' &&
                store.getState().user.gpsCoordinates.lng !== '' &&
                store.getState().user.profile.communication_preference !==
                  '' ? (
                  store.getState().user.profile.full_name
                ) : (
                  <FormattedMessage
                    id="placeholder.userWithoutName"
                    defaultMessage="Provide your missing information"
                  />
                )}
              </UsernameHeadline>
              <PhoneNumberSubtitle
                style={
                  store.getState().user.gpsCoordinates.lat !== '' &&
                  store.getState().user.gpsCoordinates.lng !== '' &&
                  store.getState().user.profile.communication_preference !== ''
                    ? { wordBreak: 'break-all' }
                    : { wordBreak: 'normal' }
                }
              >
                {store.getState().user.gpsCoordinates.lat !== '' &&
                store.getState().user.gpsCoordinates.lng !== '' &&
                store.getState().user.profile.communication_preference !==
                  '' ? (
                  formatPhoneNumberIntl(store.getState().user.phoneNumber)
                ) : (
                  <FormattedMessage
                    id="placeholder.userWithoutNameGuide"
                    defaultMessage="With your profile completed you will be able to help and be helped"
                  />
                )}
              </PhoneNumberSubtitle>
            </div>
          </MenuHeader>
        </Link>
        <Divider />
        <Link className="menu-item" to="/home">
          <HomeRoundedIcon />{' '}
          <FormattedMessage id="menu.home" defaultMessage="Home" />
        </Link>
        <Link className="menu-item" to="/home/map/filter">
          <MapRoundedIcon />{' '}
          <FormattedMessage id="menu.helpPeople" defaultMessage="I can help" />
        </Link>
        <Link className="menu-item" to="/needs">
          <RecordVoiceOverRoundedIcon />{' '}
          <FormattedMessage id="menu.needs" defaultMessage="Ask for help" />
        </Link>
        <Link className="menu-item" to="/help">
          <PeopleAltRoundedIcon />{' '}
          <FormattedMessage id="menu.help" defaultMessage="Help" />
        </Link>
        <Link className="menu-item" to="/messages">
          <MailRoundedIcon />{' '}
          <FormattedMessage id="menu.messages" defaultMessage="Messages" />
        </Link>
        <Link className="menu-item" to="/feedbacks">
          <RateReviewIcon />{' '}
          <FormattedMessage id="menu.feedbacks" defaultMessage="Feedbacks" />
        </Link>
        <Link className="menu-item" to="/profile">
          <AccountBoxRoundedIcon />{' '}
          <FormattedMessage id="menu.profile" defaultMessage="Profile" />
        </Link>
        <Link className="menu-item" to="/settings">
          <SettingsApplicationsRoundedIcon />{' '}
          <FormattedMessage id="menu.settings" defaultMessage="Settings" />
        </Link>
        <div onClick={handleLogoutDialogOpen} className="menu-item">
          <ExitToAppRoundedIcon />{' '}
          <FormattedMessage id="menu.logout" defaultMessage="Log out" />
        </div>
      </Drawer>
      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage
            id="title.logout"
            defaultMessage="Do you really want to log out?"
          />
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleLogoutDialogClose} color="primary">
            <FormattedMessage
              id="placeholder.noLogout"
              defaultMessage="No, go back"
            />
          </Button>
          <Button onClick={handleSignOut} color="secondary">
            <FormattedMessage
              id="placeholder.yesLogout"
              defaultMessage="Yes, log out"
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
