import produce from 'immer';

const INITIAL_STATE = {
  lat: '',
  lng: '',
  gotUserLocation: false,
  cannotGetUserLocation: false,
  loadingGeolocation: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@geolocation/GET_LOCATION_REQUEST': {
        draft.loadingGeolocation = true;
        break;
      }
      case '@geolocation/GET_LOCATION_SUCCESS': {
        draft.lat = action.payload.lat;
        draft.lng = action.payload.lng;
        draft.gotUserLocation = true;
        draft.cannotGetUserLocation = false;
        draft.loadingGeolocation = false;
        break;
      }
      case '@geolocation/GET_LOCATION_FAILURE': {
        draft.cannotGetUserLocation = true;
        draft.loadingGeolocation = false;
        break;
      }
      default:
    }
  });
}
