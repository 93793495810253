import styled from 'styled-components';

export const UsernameHeadline = styled.p`
  font-size: 12px;
  letter-spacing: 0.4px;
  color: white;
  margin-bottom: 2.5px;
  max-width: 200px;
`;

export const PhoneNumberSubtitle = styled.p`
  font-size: 10px;
  margin-bottom: 0px;
  letter-spacing: 1.5px;
  color: white;
  max-width: 200px;
  word-break: break-all;
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
`;
