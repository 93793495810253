/* eslint-disable camelcase */
export function signInVerificationCodeRequest(phoneNumber) {
  return {
    type: '@auth/SIGN_IN_VERIFICATION_CODE_REQUEST',
    payload: { phoneNumber },
  };
}

export function changeNumberVerificationCodeRequest(phoneNumber) {
  return {
    type: '@auth/CHANGE_NUMBER_VERIFICATION_CODE_REQUEST',
    payload: { phoneNumber },
  };
}

export function changeNumberVerificationCodeSuccess(phoneNumber) {
  return {
    type: '@auth/CHANGE_NUMBER_VERIFICATION_CODE_SUCCESS',
    payload: {
      phoneNumber,
    },
  };
}

export function changeNumberRequest(verificationCode, newPhoneNumber) {
  return {
    type: '@auth/CHANGE_NUMBER_REQUEST',
    payload: { verificationCode, newPhoneNumber },
  };
}

export function changeNumberSuccess(verificationCode, newPhoneNumber) {
  return {
    type: '@auth/CHANGE_NUMBER_SUCCESS',
    payload: {
      verificationCode,
      newPhoneNumber,
    },
  };
}

export function signInSuccess(
  access_token,
  expires_in,
  name,
  phoneNumber,
  userProfile,
  gpsCoordinates,
  id,
  avatar,
  email,
  first_name,
  last_name
) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: {
      access_token,
      expires_in,
      name,
      phoneNumber,
      userProfile,
      gpsCoordinates,
      id,
      avatar,
      email,
      first_name,
      last_name,
    },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signUpVerificationCodeRequest(
  firstName,
  lastName,
  phoneNumber,
  birthDate
) {
  return {
    type: '@auth/SIGN_UP_VERIFICATION_CODE_REQUEST',
    payload: {
      firstName,
      lastName,
      phoneNumber,
      birthDate,
    },
  };
}

export function signInRequest(verificationCode) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: {
      verificationCode,
    },
  };
}

export function signUpRequest(verificationCode) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: {
      verificationCode,
    },
  };
}

export function signUpVerificationCodeSuccess(
  firstName,
  lastName,
  phoneNumber,
  birthDate
) {
  return {
    type: '@auth/SIGN_UP_VERIFICATION_CODE_SUCCESS',
    payload: {
      firstName,
      lastName,
      phoneNumber,
      birthDate,
    },
  };
}

export function signInVerificationCodeSuccess(phoneNumber) {
  return {
    type: '@auth/SIGN_IN_VERIFICATION_CODE_SUCCESS',
    payload: {
      phoneNumber,
    },
  };
}

export function signUpSuccess(
  name,
  phoneNumber,
  userProfile,
  gpsCoordinates,
  access_token,
  expires_in,
  id,
  avatar,
  email,
  first_name,
  last_name
) {
  return {
    type: '@auth/SIGN_UP_SUCCESS',
    payload: {
      name,
      phoneNumber,
      userProfile,
      gpsCoordinates,
      access_token,
      expires_in,
      id,
      avatar,
      email,
      first_name,
      last_name,
    },
  };
}

export function refreshTokenRequest() {
  return {
    type: '@auth/REFRESH_TOKEN_REQUEST',
    payload: {},
  };
}

export function refreshTokenSuccess(access_token, expires_in) {
  return {
    type: '@auth/REFRESH_TOKEN_SUCCESS',
    payload: { access_token, expires_in },
  };
}

export function refreshTokenFailure() {
  return {
    type: '@auth/REFRESH_TOKEN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
